@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Permanent+Marker&display=swap");

.App {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #1c1150;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Permanent Marker", sans-serif;
}

.font-title {
  font-family: "Permanent Marker", sans-serif;
}

.text-gradient {
  background: linear-gradient(180deg, #dce35b 0%, #45b649 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-green {
  background: linear-gradient(180deg, #dce35b 0%, #45b649 100%);
}

.bg-gradient-purple {
  background: linear-gradient(180deg, #65b741 0%, #f0ecfc 100%);
}

.bg-gradient-yellow {
  background: linear-gradient(180deg, #ffe259 0%, #ffa751 100%);
}
