@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  width: 100%;
}

a {
  color: inherit;
  cursor: pointer;
}
